import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,{attrs:{"outlined":"","elevation":"0"}},[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[[_c('span',{staticClass:"ml-2"},[_c(VIcon,[_vm._v("mdi-wallet")]),_vm._v("e-gift Transactions History")],1)],(_vm.isShowTopup)?_c(VBtn,{staticClass:"mb-2 self-align-end",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.topupDialog = true}}},[_vm._v(" e-gift Manage ")]):_vm._e()],2),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.transactions,"loading":_vm.loading,"items-per-page":10},scopedSlots:_vm._u([{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(item.created_at.split('T')[0])+" ")]}},{key:"item.type",fn:function({ item }){return [_c(VChip,{attrs:{"dark":"","color":item.type === 'Credit' ? 'green' : 'red'}},[_vm._v(_vm._s(item.type))])]}},{key:"item.amount",fn:function({ item }){return [(item.type === 'Credit')?_c('span',[_vm._v("+"+_vm._s(item.amount))]):_c('span',[_vm._v("-"+_vm._s(item.amount))])]}}])})],1),_c(VBottomSheet,{attrs:{"scrollable":"","inset":""},model:{value:(_vm.topupDialog),callback:function ($$v) {_vm.topupDialog=$$v},expression:"topupDialog"}},[_c('TopupDialog',{attrs:{"data":_vm.user_id},on:{"soft-close":function($event){_vm.topupDialog = false;},"close":_vm.close}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }