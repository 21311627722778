<template>
  <v-app v-if="$store.state.user != null">
    <v-app-bar v-if="$route.path !== '/auth'" flat app>
      <v-btn v-if="$route.fullPath.split('/').length > 2 && !not_include.includes($route.fullPath)  && this.$store.state.user.role !== 'gym'" icon @click="$router.back()" color="black">
        <v-icon>mdi-arrow-left-circle</v-icon>
      </v-btn>
      <v-card-title>{{ $route.name }}</v-card-title>
      <v-spacer/>
      <v-col cols="auto">
        <span class="font-weight-bold">{{  $store.state.user.name }}</span> <br>
        {{  $store.state.user.role.toUpperCase() }}
      </v-col>
      <v-divider class="mr-2" vertical/>
      <v-avatar>
        <v-img :src="$store.state.s3BaseUrl + $store.state.user.avatar_url"/>
      </v-avatar>
      <v-btn color="red" icon @click="$store.commit('deAuth')">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer  v-if="$route.path !== '/auth' && ['admin'].includes($store.state.user.role)" width="280" color="#f2f2f2" app permanent class="pr-4">
      <router-link :to="'/'" class="router--">
        <div class="d-flex justify-center">
          <v-img height="128" width="260" contain src="@/assets/allblack.png" max-width="200" max-height="200"></v-img>
        </div>
      </router-link>

      <div v-for="(menu,i) in sideMenu" :key="i">

        <v-list-item v-if="!menu.subLinks" active-class="sideMenu-active"  color="transparent"
                   class="sideMenu rounded" :to="menu.to">
          <v-list-item-icon>
            <v-icon color="black">{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="black--text">{{ menu.title }}</v-list-item-title>
        </v-list-item>

        <v-list-group v-else>
            
            <template v-slot:activator>
              <v-list-item-icon>
                  <v-icon color="black">{{ menu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="black--text">{{ menu.title }}</v-list-item-title>
            </template>
          
            <v-list-item active-class="subMenusideMenu-active" v-for="sublink in menu.subLinks"  color="transparent"
                      class="subMenusideMenu rounded ml-5" :to="sublink.to">
              <v-list-item-title class="black--text">{{ sublink.title }}</v-list-item-title>
            </v-list-item>

        </v-list-group>

      </div>

    </v-navigation-drawer>

    <v-navigation-drawer  v-if="$route.path !== '/auth' && ['moderator'].includes($store.state.user.role)" width="280" color="#f2f2f2" app permanent class="pr-4">
        <div class="d-flex justify-center">
          <v-img height="128" width="260" contain src="@/assets/allblack.png" max-width="200" max-height="200"></v-img>
        </div>

      <v-list-item v-if="shouldRestrict(menu)" active-class="sideMenu-active" v-for="(menu,i) in webManager" :key="i" color="transparent"
                   class="sideMenu rounded" :to="menu.to">
        <v-list-item-icon>
          <v-icon color="black">{{ menu.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="black--text">{{ menu.title }}</v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>


    <v-navigation-drawer v-if="$route.path !== '/auth' && $store.state.user.role === 'therapy'" width="280" color="#f2f2f2" app permanent class="pr-4">
      <div class="d-flex justify-center">
        <v-img height="128" width="260" contain src="@/assets/allblack.png" max-width="200" max-height="200"></v-img>
      </div>

      <v-list-item active-class="sideMenu-active" v-for="(menu,i) in sideMenuTherapy" :key="i" color="transparent"
                   class="sideMenu rounded" :to="menu.to">
        <v-list-item-icon>
          <v-icon color="black">{{ menu.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="black--text">{{ menu.title }}</v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>


    <v-navigation-drawer v-if="$route.path !== '/auth' && $store.state.user.role === 'gym' && $store.state.gymType === 'exclusive'" width="280" color="#f2f2f2" app permanent class="pr-4">
      <div class="d-flex justify-center">
        <v-img height="128" width="260" contain src="@/assets/allblack.png" max-width="200" max-height="200"></v-img>
      </div>

      <v-list-item active-class="sideMenu-active" v-for="(menu,i) in sideMenuExGym" :key="i" color="transparent"
                   class="sideMenu rounded" :to="menu.to">
        <v-list-item-icon>
          <v-icon color="black">{{ menu.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="black--text">{{ menu.title }}</v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>

    <v-navigation-drawer v-if="$route.path !== '/auth' && $store.state.user.role === 'gym' && $store.state.gymType === 'normal'" width="280" color="#f2f2f2" app permanent class="pr-4">
      <div class="d-flex justify-center">
        <v-img height="128" width="260" contain src="@/assets/allblack.png" max-width="200" max-height="200"></v-img>
      </div>

      <v-list-item active-class="sideMenu-active" v-for="(menu,i) in sideMenuGym" :key="i" color="transparent"
                   class="sideMenu rounded" :to="menu.to">
        <v-list-item-icon>
          <v-icon color="black">{{ menu.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="black--text">{{ menu.title }}</v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>

    
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    pathForGym:'',
    not_include:['/faq/add', '/faq/sub','/faq/main'],
    restrictedItems: [
      '/manage-users',
      '/subscriptions',
      '/commercial-gyms',
      '/commercial-gyms',
      '/members',
      '/trainers',
      '/doctors'
    ],
    sideMenuExGym:[
      {
        title: 'Gym Manager',
        icon: 'mdi-dumbbell',
        to: '',
      },
      {
        title: 'Finances',
        icon: 'mdi-account-group',
        to: ''
      }
    ],sideMenuGym:[
      {
        title: 'Gym Manager',
        icon: 'mdi-dumbbell',
        to: ''
      },
      {
        title: 'Finances',
        icon: 'mdi-account-group',
        to: ''
      },
      {
        title: 'Exercise Bank',
        icon: 'mdi-dumbbell',
        to: '/gyms/exercise-bank'
      },
      
      {
        title: 'Preset Create',
        icon: 'mdi-weight-lifter',
        to: ''
      }
      ,
      {
        title: 'Subscribed Users',
        icon: 'mdi-account-multiple-check',
        to: '/gym-user-manage'
      }
    ],
    sideMenuTherapy:[
      {
        title: 'Therapy Profile',
        icon: 'mdi-account',
        to: '/therapy-profile'
      },
      {
        title: 'Meetings',
        icon: 'mdi-account-group',
        to: '/therapy-meetings'
      },
    ],
    sideMenu: [
    
      {
        title: 'UserRole Manager',
        icon: 'mdi-account-group',
        to: '/manage-users'
      }, {
        title: 'User Profiles',
        icon: 'mdi-account-tie',
        to: '/manage-users',
        subLinks : [
            {
            title: 'Members',
            icon: 'mdi-account-group',
            to: '/members'
          },
          {
            title: 'Coaches',
            icon: 'mdi-account-tie',
            to: '/trainers'
          },
          {
            title: 'Medical Professionals',
            icon: 'mdi-doctor',
            to: '/doctors'
          },
        ]
      },
      {
        title: 'Finance',
        icon: 'mdi-chart-box-multiple-outline',
        to: '/manage-users',
        subLinks : [
              {
              title: 'Profit & Loss',
              icon: 'mdi-chart-box-multiple-outline',
              to: '/lossProfit-view'
            },
            {
              title: 'All Tax Modifier',
              icon: 'mdi-percent',
              to: '/tax-manage'
            },{
              title: 'E-gift Topups',
              icon: 'mdi-wallet-plus-outline',
              to: '/ewallet-report'
            },{
              title: 'Store Manager',
              icon: 'mdi-store',
              to: '/shop'
            },{
              title: 'Vending Machine',
              icon: 'mdi-coffee-maker',
              to: '/vendor-orders'
            },
        ]
      },
      {
        title: 'Food Bank',
        icon: 'mdi-food',
        to: '/food-data',
        subLinks : [            
          {
            title: 'Food Data',
            icon: 'mdi-food',
            to: '/food-data'
          },{
            title: 'Food Data Approval',
            icon: 'mdi-hamburger-off',
            to: '/client-meal'
          },

        ]
      },{
        title: 'Exercise Bank',
        icon: 'mdi-dumbbell',
        to: '/food-data',
        subLinks : [            
            {
            title: 'Exercise Bank',
            icon: 'mdi-dumbbell',
            to: '/exercise-bank'
          },
          {
            title: 'Preset Create Admin',
            icon: 'mdi-weight-lifter',
            to: '/admin/preset-manage'
          },

        ]
      },
      {
        title: 'Courtesy Note',
        icon: 'mdi-note-edit-outline',
        to: '/courtesy-note'
      },{
        title: 'Library',
        icon: 'mdi-book',
        to: '/food-data',
        subLinks : [            
            {
            title: 'Resources',
            icon: 'mdi-book',
            to: '/resources'
          },
          {
            title: 'Newsletters',
            icon: 'mdi-email-newsletter',
            to: '/newsletters'
          },
        ]
      },
      {
        title: 'F&Q Manage',
        icon: 'mdi-face-agent',
        to: '/faq/main'
      }, 
      {
        title: 'Exclusive Gyms',
        icon: 'mdi-weight-lifter',
        to: '/exclusive-gyms'
      },
      {
        title: 'Commercial Gyms',
        icon: 'mdi-weight-lifter',
        to: '/food-data',
        subLinks : [            
          
        {
          title: 'Manage Gyms',
          icon: 'mdi-weight-lifter',
          to: '/commercial-gyms'
        },
      {
        title: 'Gym Access Log',
        icon: 'mdi mdi-door-closed-lock',
        to: '/gym-access-log'
      }
        ]
      },
      {
        title: 'Gym Services',
        icon: 'mdi-check-decagram',
        to: '/gym-services/manage'
      },{
        title: 'Ads Gallery',
        icon: 'mdi-view-gallery-outline',
        to: '/site-ads'
      },
      {
        title: 'Subscriptions',
        icon: 'mdi-currency-usd',
        to: '/subscriptions'
      },
      
      {
        title: 'Promo Code Creation',
        icon: 'mdi-ticket-percent',
        to: '/coupon-creation'
      },
      {
        title: 'Holiday manage',
        icon: 'mdi-tent',
        to: '/holiday-calender'
      },
      {
        title: 'Therapy Manage',
        icon: 'mdi-doctor',
        to: '/therapy-manage'
      },
      {
        title: 'APP Custom Notification',
        icon: 'mdi-bell-ring',
        to: '/custom-notification'
      },{
        title: 'Widget Manage',
        icon: 'mdi-widgets',
        to: '/food-data',
        subLinks : [            
          {
          title: 'Coach Widget Manage',
          icon: 'mdi-chart-arc',
          to: '/trainer-widgets'
        },
        {
          title: 'Member Widget Manage',
          icon: 'mdi-widgets',
          to: '/client-widgets'
        },
        ]
      },
      
      {
        title: 'Coach Classes',
        icon: 'mdi-google-classroom',
        to: '/trainer-classes'
      },{
        title: 'Vendor Images',
        icon: 'mdi-view-gallery',
        to: '/vendor-image'
      }

    ],
    webManager: [
      {
        title: 'Manage Breadcrumbs',
        icon: 'mdi-navigation-variant-outline',
        to: '/home-settings'
      },
      {
        title: 'Success Story Manage',
        icon: 'mdi-account-check',
        to: '/success-story'
      },
      {
        title: 'Previous Project Manage',
        icon: 'mdi-adjust',
        to: '/previous-projects'
      },
      {
        title: 'Trainer Appointments',
        icon: 'mdi-account-arrow-up-outline',
        to: '/appointments'
      },

    ]
  }),
  methods:{
    shouldRestrict(item){
      if(this.$store.state.user.role === 'admin'){
        return true
      } else {
        if(this.restrictedItems.includes(item.to)){
          return false
        } else {
          return true
        }
      }
    },
    
  },
  mounted() {
      this.sideMenuExGym[0].to = '/gyms/' + this.$store.state.user.id + '/details';
      this.sideMenuExGym[1].to = '/gyms/' + this.$store.state.user.id + '/finances';
      this.sideMenuGym[0].to = '/gyms/' + this.$store.state.user.id + '/details';
      this.sideMenuGym[1].to = '/gyms/' + this.$store.state.user.id + '/finances';
      this.sideMenuGym[3].to = '/gyms/' + this.$store.state.user.id + '/preset-manage';
      this.sideMenuGym[5].to = '/gyms/' + this.$store.state.user.id + '/user-workouts';
  },
  beforeMount() {
    this.$store.commit('setLocalAuth');
  },
};
</script>

<style scoped lang="scss">
.sideMenu {
  transition: all 0.5s ease;
  border-radius: 32px !important;
  margin: 4px !important;
}

.sideMenu:hover {
  background: #ffa200;
  transition: all 0.5s ease;
  border-radius: 32px !important;
  margin: 4px !important;
}

.sideMenu-active {
  background: #ffa200;
  border-radius: 32px !important;
  transition: all 0.5s ease;
  margin: 4px !important;
}

.subMenusideMenu{
    transition: all 0.5s ease;
    border-radius: 32px !important;
    margin-top: 4px !important;
}


.subMenusideMenu:hover {
  background: #ffa200;
  transition: all 0.5s ease;
  border-radius: 32px !important;
  margin-top: 4px !important;
}

.subMenusideMenu-active {
  background: #ffa200;
  border-radius: 32px !important;
  transition: all 0.5s ease;
  margin-top: 4px !important;
}

</style>
